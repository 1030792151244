import get from 'lodash/get'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/grid'
import styled, { useTheme } from 'styled-components'
import { IoClose } from 'react-icons/io5'
import { size } from 'styled-theme'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import Modal from '../../molecules/Modal'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import UserMatchItem, { getStatusPalette, USER_MATCH_STATUS_ACCEPTED, USER_MATCH_STATUS_PENDING, USER_MATCH_STATUS_REJECTED } from '../../organisms/UserMatchItem'
import { handleErrorResponse } from '../../../utlils/general'
import { useAuth } from '../../../contexts/auth'
import { useLoading } from '../../../contexts/loading'
import { acceptUserMatch, getUserMatches, rejectUserMatch } from '../../../api/user-matches'
import { getChatMembers } from '../../../api/messages'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 3rem 3rem;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const StyledContentBox = styled(ContentBox)`
  padding: 1rem;
`

const USER_MATCH_STATUSES = [
  { label: '等待中', value: USER_MATCH_STATUS_PENDING },
  { label: '已配對', value: USER_MATCH_STATUS_ACCEPTED },
  { label: '拒絕左', value: USER_MATCH_STATUS_REJECTED },
]

const StatusFilterBar = ({ dataCount, filteredStatus, setFilteredStatus, style }) => {
  const theme = useTheme()
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      margin="-1rem -1rem 0"
      padding="0.5rem 1rem"
      style={{
        background: `${theme.palette.grayscale[5]}B3`,
        backdropFilter: 'blur(2px)',
        ...style,
      }}
    >
      <Flex alignItems="center">
        {USER_MATCH_STATUSES.map((status, sIdx) => {
          const { palette, paletteIndex } = getStatusPalette(status.value)
          const isSelected = filteredStatus === status.value
          return (
            <Button
              key={sIdx}
              onClick={() => setFilteredStatus(status.value)}
              variant={isSelected ? 'solid' : 'outline'}
              palette={palette}
              padding="0.4rem"
              style={{
                borderColor: theme.palette[palette][paletteIndex],
                marginLeft: sIdx > 0 ? '0.5rem' : 0,
                ...(isSelected ? {
                  background: theme.palette[palette][paletteIndex],
                } : {}),
              }}
            >
              <Text
                palette={isSelected ? 'white' : palette}
                paletteIndex={isSelected ? 0 : paletteIndex}
              >
                {status.label}
              </Text>
            </Button>
          )
        })}
        {!!filteredStatus && (
          <Button
            variant="text"
            padding={0}
            onClick={() => setFilteredStatus(null)}
            style={{ marginLeft: '0.5rem' }}
          >
            <IoClose size={24} />
          </Button>
        )}
      </Flex>
      <Box marginLeft="0.5rem">
        <Text bold variant="h3">({dataCount || 0})</Text>
      </Box>
    </Flex>
  )
}

StatusFilterBar.propTypes = {
  dataCount: PropTypes.number,
  filteredStatus: PropTypes.string,
  setFilteredStatus: PropTypes.func,
  style: PropTypes.object,
}

const MatchesPage = () => {
  const [userMatches, setUserMatches] = useState([])
  const [filteredStatus, setFilteredStatus] = useState(null)
  const [unreadChatIds, setUnreadChatIds] = useState([])
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(null)
  const [avatarModalOpen, setAvatarModalOpen] = useState(false)
  const { loading, setLoading } = useLoading()
  const { auth, setAuth } = useAuth()

  const userId = get(auth.user, 'user_id')

  const fetchUserMatchesFromApi = useCallback(() => {
    const userMatchParams = {
      join: '[init_user,respond_user]',
      orderByDesc: 'match_date',
      ...(filteredStatus ? {
        status: filteredStatus,
      } : {}),
    }
    return getUserMatches(userMatchParams)
      .then(({ data: matches }) => {
        setUserMatches(matches)
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
  }, [filteredStatus, setAuth])

  const fetchUnreadChatIdsFromApi = useCallback(() => {
    const unreadChatMemberParams = {
      user_id: userId,
      unread: 1,
    }
    return getChatMembers(unreadChatMemberParams)
      .then(({ data: unreadChatMembers }) => {
        const chatIds = (unreadChatMembers || []).map(({ chat_id }) => chat_id)
        setUnreadChatIds(chatIds)
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
  }, [userId, setAuth])

  const respondUserMatchToApi = useCallback((userMatchId, action) => {
    setLoading(true)
    const respondUserMatch = action === 'accept' ? acceptUserMatch : rejectUserMatch
    return respondUserMatch(userMatchId)
      .then(({ data: updatedUserMatch }) => {
        setUserMatches((oriMatches) => oriMatches.map((match) => {
          if (match.user_match_id !== updatedUserMatch.user_match_id) {
            return match
          }
          return {
            ...match,
            ...updatedUserMatch,
          }
        }))
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, setAuth])

  const acceptUserMatchToApi = useCallback((userMatchId) => {
    return respondUserMatchToApi(userMatchId, 'accept')
  }, [respondUserMatchToApi])

  const rejectUserMatchToApi = useCallback((userMatchId) => {
    return respondUserMatchToApi(userMatchId, 'reject')
  }, [respondUserMatchToApi])

  const onClickAvatar = useCallback((avatarUrl) => {
    if (avatarUrl) {
      setCurrentAvatarUrl(avatarUrl)
      setAvatarModalOpen(true)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    Promise.all([
      fetchUserMatchesFromApi(),
      fetchUnreadChatIdsFromApi(),
    ])
      .finally(() => {
        setLoading(false)
      })
  }, [fetchUnreadChatIdsFromApi, fetchUserMatchesFromApi, setLoading])

  return (
    <StyledViewport>
      <StyledContentBox>
        <StatusFilterBar
          dataCount={(userMatches || []).length}
          setFilteredStatus={setFilteredStatus}
          filteredStatus={filteredStatus}
          style={{ position: 'sticky', top: '-1rem', zIndex: 1 }}
        />
        {(userMatches || []).map((userMatch) => {
          return (
            <Box key={userMatch.user_match_id} mb="1rem">
              <UserMatchItem
                userMatch={userMatch}
                respondable={userId === userMatch.respond_user_id}
                unread={unreadChatIds.includes(userMatch.chat_id)}
                onAccept={acceptUserMatchToApi}
                onReject={rejectUserMatchToApi}
                onClickAvatar={onClickAvatar}
              />
            </Box>
          )
        })}
        <Modal
          isOpen={avatarModalOpen}
          onClose={() => setAvatarModalOpen(false)}
          showCloseBtn
        >
          <Image
            key={currentAvatarUrl}
            src={currentAvatarUrl}
            width="100%"
            height="100%"
            borderRadius="0.5rem"
            style={{ maxHeight: '78vh', objectFit: 'contain' }}
          />
        </Modal>
        {!loading && !userMatches.length && (
          <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center">
            <Text variant="h3">暫時未有配對住喎</Text>
            <Text variant="h3">去叫Crush仔幫你搵下啦！</Text>
            <Box mt="1rem">
              <Button to="/">
                <Text variant="h3" palette="white">👈🏻 即去！</Text>
              </Button>
            </Box>
          </Flex>
        )}
      </StyledContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default MatchesPage
