import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { Box, Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { handleErrorResponse } from '../../../utlils/general'
import { shareFile } from '../../../utlils/file'
import { useLoading } from '../../../contexts/loading'
import { useAuth } from '../../../contexts/auth'
import api from '../../../api'

const sendPrompt = (body) => {
  return api.post('/prompts/send', body, 'bearer')
}

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 3rem 3rem;
  align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const PlaygroundPage = () => {
  const [textPrompt, setTextPrompt] = useState('')
  const [imgPrompt, setImgPrompt] = useState('')
  const [reply, setReply] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const { auth, setAuth } = useAuth()
  const { setLoading } = useLoading()

  const sendPromptToApi = useCallback((prompt, type) => {
    setLoading(true)
    return sendPrompt({ content: prompt, type })
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, setAuth])

  const sendTextPromptToApi = useCallback(() => {
    return sendPromptToApi(textPrompt, 'text')
      .then(({ data }) => {
        setReply(data)
      })
  }, [textPrompt, sendPromptToApi])

  const sendImagePromptToApi = useCallback(async () => {
    return sendPromptToApi(imgPrompt, 'image')
      .then(({ data }) => {
        setImageUrl(data)
      })
  }, [imgPrompt, sendPromptToApi])

  const shareImage = useCallback(async () => {
    try {
      setLoading(true)
      await shareFile(imageUrl, 'image.jpg')
    } catch (err) {
      alert(err.stack || err.message)
    } finally {
      setLoading(false)
    }
  }, [imageUrl, setLoading])

  if (auth.user?.role > 0) {
    return null
  }

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">Playground</Text>
        </Flex>
        <Input
          type="textarea"
          value={textPrompt}
          onChange={(e) => setTextPrompt(e.target.value)}
        />
        <Button
          fullWidth
          onClick={sendTextPromptToApi}
          style={{ marginTop: '0.5rem' }}
        >
          Get Reply
        </Button>
        {!!reply && (
          <Box mt="0.5rem">
            <Text>Reply:<br />{reply}</Text>
          </Box>
        )}
        <Box mt="3rem">
          <Input
            type="textarea"
            value={imgPrompt}
            onChange={(e) => setImgPrompt(e.target.value)}
          />
          <Button
            fullWidth
            onClick={sendImagePromptToApi}
            style={{ marginTop: '0.5rem' }}
          >
            Generate Image
          </Button>
        </Box>
        {!!imageUrl && (
          <Flex mt="0.5rem" flexDirection="column">
            <Text>Result:</Text>
            <Image
              src={imageUrl}
              width="100%"
              height="100%"
              borderRadius="0.5rem"
              style={{ maxWidth: 400 }}
            />
            <Button onClick={shareImage} style={{ marginTop: '0.5rem' }}>
              Share
            </Button>
          </Flex>
        )}
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default PlaygroundPage
