export const downloadFile = async (fileUrl, filename) => {
  const res = await fetch(fileUrl)
  const blob = await res.blob()
  const url = window.URL.createObjectURL(blob)
  const linkTag = document.createElement('a')
  linkTag.style.display = 'none'
  linkTag.href = url
  linkTag.download = filename
  document.body.appendChild(linkTag)
  linkTag.click()
  window.URL.revokeObjectURL(url)
}

export const shareFile = async (fileUrl, filename) => {
  try {
    const res = await fetch(fileUrl)
    const blob = await res.blob()
    const file = new File([blob], filename, {
      type: blob.type,
      lastModified: Date.now(),
    })
    const shareData = {
      title: filename,
      text: 'Sharing image generated by Crushk.co!',
      files: [file],
    }
    if (window.navigator.canShare && window.navigator.canShare(shareData)) {
      await window.navigator.share(shareData)
    } else {
      throw new Error('Sharing is not support')
    }
  } catch (err) {
    if (err.name !== 'AbortError') {
      throw err
    }
  }
}
