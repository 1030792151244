import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import { useTheme } from 'styled-components'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'

const Avatar = ({ src, size, style, ...props }) => {
  const theme = useTheme()
  return (
    <Flex
      width={size}
      height={size}
      justifyContent="center"
      alignItems="center"
      style={{
        flexShrink: 0,
        aspectRatio: 1,
        borderRadius: '50%',
        border: `1px solid ${theme.palette.primary[0]}`,
        backgroundColor: `${theme.palette.secondary[5]}`,
        ...style,
      }}
      {...props}
    >
      {src ? (
        <Image src={src} width={size - 2} height={size - 2} circle />
      ) : (
        <Text style={{ fontSize: (size - 2) / 2 }}>🤖</Text>
      )}
    </Flex>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
}

Avatar.defaultProps = {
  src: null,
  size: 36,
  style: {},
}

export default Avatar
