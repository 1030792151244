import React, { useCallback, useMemo, useState } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { palette, size } from 'styled-theme'
import { IoArrowBack } from 'react-icons/io5'
import { Flex } from '@rebass/grid'
import { useParams } from 'react-router-dom'
import Chatroom from '../../organisms/Chatroom'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import Modal from '../../molecules/Modal'
import Avatar from '../../molecules/Avatar'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import useChatMessages from '../../../hooks/useChatMessages'
import { useAuth } from '../../../contexts/auth'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 1rem 3rem;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const ChatHeader = styled(Flex)`
  align-items: center;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 0.5rem 0.8rem;
  border-bottom: 4px solid ${palette('primary', 0)};
`

const StyledContentBox = styled(ContentBox)`
  height: 100%;
  padding: 0.5rem;
`

const ChatPage = () => {
  const { auth } = useAuth()
  const { chatId } = useParams()
  const { chat: currentChat, messages, sendChatMessage } = useChatMessages(chatId)
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(null)
  const [avatarModalOpen, setAvatarModalOpen] = useState(false)

  const otherChatUsers = useMemo(() => {
    if (!currentChat) {
      return []
    }
    return (currentChat.chat_members || []).reduce((users, chatMember) => {
      const { user } = chatMember
      if (user.user_id !== auth.user?.user_id) {
        return [...users, user]
      }
      return users
    }, [])
  }, [currentChat, auth.user])

  const onClickAvatar = useCallback((avatarUrl) => {
    if (avatarUrl) {
      setCurrentAvatarUrl(avatarUrl)
      setAvatarModalOpen(true)
    }
  }, [])

  return (
    <StyledViewport>
      <StyledContentBox>
        <ChatHeader>
          <Button variant="text" to="/matches" padding="0.125rem" style={{ marginRight: '0.5rem' }}>
            <IoArrowBack size={24} />
          </Button>
          {otherChatUsers.length > 0 && (
            <Flex alignItems="center">
              <Flex mr="0.5rem">
                {otherChatUsers.slice(0, 3).map(({ avatar_url }, aIdx) => (
                  <Avatar
                    key={`avatar-${aIdx}`}
                    src={avatar_url}
                    size={42}
                    onClick={() => onClickAvatar(avatar_url)}
                    style={{
                      cursor: avatar_url ? 'pointer' : 'auto',
                      marginLeft: aIdx > 0 ? -28 : 0,
                      zIndex: -aIdx,
                    }}
                  />
                ))}
              </Flex>
              <Text variant="h3" bold>
                {otherChatUsers.map(({ display_name }) => display_name).join(', ')}
              </Text>
            </Flex>
          )}
        </ChatHeader>
        <Chatroom
          flex={1}
          style={{ minHeight: 0 }}
          userId={get(auth.user, 'user_id')}
          messages={messages}
          readOnly={!currentChat || !get(auth.user, 'active') || !get(auth.user, 'email_verified')}
          onMessageSend={sendChatMessage}
          headerHidden
        />
      </StyledContentBox>
      <Modal
        isOpen={avatarModalOpen}
        onClose={() => setAvatarModalOpen(false)}
        showCloseBtn
      >
        <Image
          key={currentAvatarUrl}
          src={currentAvatarUrl}
          width="100%"
          height="100%"
          borderRadius="0.5rem"
          style={{ maxHeight: '78vh', objectFit: 'contain' }}
        />
      </Modal>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ChatPage
